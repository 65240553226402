import React from "react";
import { Link } from "gatsby";

import Layout from "../layouts/ja";
import Img_benefit_1 from "../images/home/benefit-1.svg";
import Img_benefit_2 from "../images/home/benefit-2.svg";
import Img_benefit_4 from "../images/home/benefit-4.svg";
import Img_benefit_5 from "../images/home/benefit-5.svg";
import Img_benefit_6 from "../images/home/benefit-6.svg";
import Img_benefit_8 from "../images/home/benefit-8.svg";
import SEO from "../components/seo";
import { useAuth0 } from "@auth0/auth0-react";
import { saveState } from "../state/utils/localStorage";
import { HomeBanner } from "../components/Carousel";
import moment from "moment";

const IndexPage = (props) => {
  const { loginWithRedirect } = useAuth0();
  const isAuth0Enabled = process.env.GATSBY_ENABLE_AUTH0 === "true";

  return (
    <Layout location={props.location}>
      <SEO title="Home" />

      <HomeBanner lang="ja" />

      <div className="home__benefit text--center">
        <div className="container container--500">
          <h1>会員になって会員限定の特典をお楽しみください</h1>
          <p>
            アヤナリワードではライフスタイルにあわせた魅力あふれるご滞在＆アクティビティパッケージをご用意しています
          </p>
        </div>
        <div className="container">
          <div className="home__benefit__list__wrapper">
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_1} />
              </div>
              <div className="home__benefit__list__title">
                追加ボーナスポイント
              </div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_2} />
              </div>
              <div className="home__benefit__list__title">
                会員限定ルーム料金割引
              </div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_4} />
              </div>
              <div className="home__benefit__list__title">
                お部屋の無料アップグレード
              </div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_5} />
              </div>
              <div className="home__benefit__list__title">
                レイトチェックアウト
              </div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_6} />
              </div>
              <div className="home__benefit__list__title">
                お誕生日期間にご滞在時にギフト贈呈
              </div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_8} />
              </div>
              <div className="home__benefit__list__title">
                アーリーチェックイン
              </div>
            </div>
          </div>
          <Link
            to="/ja/member-benefits"
            key="home-member-benefits"
            className="home__benefit__action"
            data-hover="もっと見る">
            <span>もっと見る</span>
          </Link>
        </div>
      </div>

      <div className="home__earn">
        <div className="container">
          <h2 className="home__earn__title">ポイントを獲得する</h2>
          <div className="home__earn__subtitle">
            アヤナリワードは、アヤナグループのすべてのホテルでご利用いただけます。
            今すぐ会員になり 特典をお楽しみください。
          </div>
          <div className="home__earn__action">
            {isAuth0Enabled ? (
              <a
                onClick={() => {
                  const href = `/ja/member`;
                  saveState("redirectUrl", href);
                  loginWithRedirect({
                    authorizationParams: {
                      ui_locales: "ja",
                      screen_hint: "signup",
                      redirect_uri: `${window.location.origin}/ja/callback`,
                    },
                    appState: { target: "redirectUrl" },
                  });
                }}
                key="home-signup"
                className="button button--cta">
                今すぐ会員になる
              </a>
            ) : (
              <Link
                to="/ja/signup"
                key="home-signup"
                className="button button--cta">
                今すぐ会員になる
              </Link>
            )}
            {isAuth0Enabled ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                onClick={() => {
                  const href = `/ja/member`;
                  saveState("redirectUrl", href);
                  loginWithRedirect({
                    authorizationParams: {
                      ui_locales: "ja",
                      redirect_uri: `${window.location.origin}/ja/callback`,
                    },
                    appState: { target: "redirectUrl" },
                  });
                }}
                key="home-login"
                className="link link--sm">
                既に会員ですか？ <b>サインインする</b>
              </a>
            ) : (
              <Link to="/ja/login" key="home-login" className="link link--sm">
                既に会員ですか？ <b>サインインする</b>
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className="home__points">
        <div className="container container--600 tablet--hide">
          <h1>アヤナリワードのポイント</h1>
          <p>
            ご利用のたびにポイントを獲得し、ポイントを使ってサービスをご利用ください。
          </p>
        </div>
        <div className="container">
          <div className="home__points__row">
            <div className="home__points__col">
              <Link
                to="/ja/faq"
                key="faq-home"
                className="home__points__col__inner">
                <h2 className="home__points__col__title">
                  ポイントを獲得する{" "}
                  <span className="mobile--only">&nbsp;ポイント</span>
                </h2>
                <div className="home__points__col__subtitle">
                  どのようにしてより多くのポイントを獲得するか学ぶ
                </div>
                <div className="button button--cta mart--xl">もっと見る</div>
              </Link>
            </div>
            <div className="home__points__col">
              <Link to="/ja/reward" className="home__points__col__inner">
                <h2 className="home__points__col__title">
                  引き換え <span className="mobile--only">&nbsp;ポイント</span>
                </h2>
                <div className="home__points__col__subtitle">
                  得したポイントで獲得できるものの全リストをご覧ください
                </div>
                <div className="button button--cta mart--xl">もっと見る</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
